import Landing from '../pages';

export const LANDING_ROUTES = [
	{
		name: 'home',
		path: '/',
		exact: true,
		component: Landing.HomeLanding,
	},
	{
		name: 'faq',
		path: '/faq',
		exact: true,
		component: Landing.Faq,
	},
	{
		name: 'service',
		path: '/service',
		exact: true,
		component: Landing.Service,
	},
	{
		name: 'personal-assistance',
		path: '/impressum',
		exact: true,
		component: Landing.PersonalAssistance,
	},
	{
		name: 'data-protection',
		path: '/datenschutzerklärung',
		exact: true,
		component: Landing.DataProtection,
	},
	{
		name: 'not-found',
		path: '*',
		exact: true,
		component: Landing.NotFound,
	},
];
