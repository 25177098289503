import React from 'react';
import { renderRoutes } from 'react-router-config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactTitle } from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import Footer from '@landing/sections/footer';
import Header from '../Header';

const LandingLayout = ({ route }: any) => {
	const { pathname } = useLocation();
	const namePage = pathname === '/' ? 'Pav Wien | Dashboard' : `Pav Wien | ${pathname.split('/')[1]}`;
	const headerType = namePage === 'Pav Wien | Dashboard' ? 'landing' : 'white';

	return (
		<>
			<ReactTitle title={namePage} />
			<div className="min-h-screen flex flex-col">
				<Header variant={headerType} />
				{renderRoutes(route.routes)}
				<Footer />
			</div>
		</>
	);
};

export default LandingLayout;
