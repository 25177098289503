import React from 'react';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';
import LandingLayout from './components/Layout';
import { LANDING_ROUTES } from './router';

const AppLanding = () => {
	return (
		<BrowserRouter>
			{renderRoutes([
				{
					component: LandingLayout,
					routes: LANDING_ROUTES,
				},
			])}
		</BrowserRouter>
	);
};

export default AppLanding;
