import React from 'react';
import { Button } from '@components/index';
import { FaceBook, InstagramIcon, LogoIcon, TikTokIcon, TumblrIcon, TwitterIcon } from '@assets/icons';
import { Link } from 'react-router-dom';
import { MenuItems } from '@landing/components';
import { MenuItemsEnum } from '@landing/components/MenuItems/MenuItems';
import { webProdUrl } from '@utils/consts';
import { ButtonVariantEnum } from '@components/Button/types';
import clsx from "clsx";
import styles from './styles.module.scss';


const Footer = () => {
	return (
		<div className={clsx("rounded-t-4xl", styles.container)}>
			<div className="container mx-auto py-16">
				<div className="flex flex-row">
					<div className="w-1/2">
						<div className="flex flex-row items-center mb-6">
							<LogoIcon height={100} width={100} />
							<p className="text-white text-5xl font-light ml-4">PAV WIEN</p>
						</div>
						<p className="text-white font-light mb-6 text-xl tracking-wider">
							Persönliche Assistenz ist Freiheit trotz Handicap!
						</p>
					</div>
					<div className="w-1/2 flex flex-row mt-auto">
						<ul className="mx-auto">
							<li className="flex flex-row items-center mb-4">
								<a
									className="flex flex-row items-center transition duration-300 easy-in-out hover:opacity-70"
									href="https://www.facebook.com/pav.wien/"
									rel="noreferrer"
									target="_blank"
								>
									<FaceBook className="text-white" height={20} width={20} />
									<span className="ml-2 font-light text-white">Facebook</span>
								</a>
							</li>
							<li className="flex flex-row items-center mb-4">
								<a
									className="flex flex-row items-center transition duration-300 easy-in-out hover:opacity-70"
									href="https://www.tiktok.com/@pavpersoenlicheassistenz?is_from_webapp=1&sender_device=pc"
									rel="noreferrer"
									target="_blank"
								>
									<TikTokIcon fill="#fff" height={20} width={20} />
									<span className="ml-2 font-light text-white">TikTok</span>
								</a>
							</li>
							<li className="mb-4">
								<a
									className="flex flex-row items-center transition duration-300 easy-in-out hover:opacity-70"
									href="https://twitter.com/PavWien"
									rel="noreferrer"
									target="_blank"
								>
									<TwitterIcon className="text-white" height={20} width={20} />
									<span className="ml-2 font-light text-white">Twitter</span>
								</a>
							</li>
							<li className="mb-4">
								<a
									className="flex flex-row items-center transition duration-300 easy-in-out hover:opacity-70"
									href="https://www.instagram.com/pavpersonalassistance/"
									rel="noreferrer"
									target="_blank"
								>
									<InstagramIcon className="text-white" height={20} width={20} />
									<span className="ml-2 text-white">Instagram</span>
								</a>
							</li>
							<li className="flex flex-row items-center">
								<a
									className="flex flex-row items-center transition duration-300 easy-in-out hover:opacity-70"
									href="https://pav-wien.tumblr.com/"
									rel="noreferrer"
									target="_blank"
								>
									<TumblrIcon className="text-white" height={20} width={20} />
									<span className="ml-2 text-white">Tumblr</span>
								</a>
							</li>
						</ul>
						<MenuItems type={MenuItemsEnum.footer} />
						<ul className="ml-auto">
							<li className="mb-4">
								<Link className="text-white font-light transition duration-300 easy-in-out hover:opacity-70" to="/faq">
									FAQ
								</Link>
							</li>
							<li className="mb-4">
								<Link className="text-white transition font-light duration-300 easy-in-out hover:opacity-70" to="/service">
									Service für Sie
								</Link>
							</li>
							<li className="mb-4">
								<Link
									className="text-white transition font-light duration-300 easy-in-out hover:opacity-70"
									to="/impressum"
								>
									Impressum
								</Link>
							</li>
							<li>
								<Link
									className="text-white transition font-light duration-300 easy-in-out hover:opacity-70"
									to="/datenschutzerklärung"
								>
									Datenschutzerklärung
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Footer;
