import React from 'react';
import Button from '@components/Button';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import MenuItems from '@landing/components/MenuItems';
import { MenuItemsEnum } from '@landing/components/MenuItems/MenuItems';
import LogoIconAnimation from '@assets/icons/LogoIconAnimation';
import { webProdUrl } from '@utils/consts';
import { ButtonVariantEnum } from '@components/Button/types';
import styles from './Header.module.scss';

const HeaderType = {
	default: {
		header: 'header-default',
		container: 'header-default-container',
		text: 'header-default-text',
	},
	white: {
		header: 'header-white',
		container: 'header-white-container',
		text: 'header-white-text',
	},
	landing: {
		header: 'header-landing',
		container: 'header-landing-container',
		text: 'header-landing-text',
	},
};

interface HeaderInterface {
	variant: keyof typeof HeaderType;
}

const Header = ({ variant = 'default' }: HeaderInterface) => {
	return (
		<div className={styles[HeaderType[variant].header]}>
			<div
				className={clsx(
					styles[HeaderType[variant].container],
					'container mx-auto flex flex-row items-center justify-between py-4',
				)}
			>
				<Link className="flex flex-row items-center" to="/">
					<LogoIconAnimation height={50} width={50} withColor={variant === 'white'} />
					<span className={`${styles[HeaderType[variant].text]} text-2xl pl-3`}>PAV WIEN</span>
				</Link>
				<MenuItems
					className={clsx(styles[HeaderType[variant].text], 'text-lg text-center opacity opacity-50 hover:opacity-80')}
					type={MenuItemsEnum.default}
				/>
				<Button
					className="px-6 py-3 rounded-full"
					text="Einloggen"
					variant={ButtonVariantEnum.landing}
					onClick={() => window.open(webProdUrl, '_blank')}
				/>
			</div>
		</div>
	);
};

export default Header;
